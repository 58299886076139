import { exists } from '../base/dom-utils.js';
import WrNonParticipatingBrandConfig from '../base/aem-configs/wr-non-participating-brands-config.js';

class GlobalNavigation {
  constructor() {
    if (exists('.navbar')) {
      this.hideWRWhenNotParticipating();
    }
    if(exists('.wyndham-rewards-content')) {
      $('.wyndham-rewards-content .dropdown-toggle-myacc').on('click', function() {
        $('body').toggleClass('login-dd-open', !$('.wyndham-rewards-content').hasClass('open'));
      });
      $(document).on('click', function(event) {
        // Check if the click was outside of the '.wyndham-rewards-content .sign-in' element
        if (
          !$(event.target).closest('.wyndham-rewards-content .sign-in button, .wyndham-rewards-content .sign-in a')
            .length
        ) {
          $('body').removeClass('login-dd-open');
        }
      });
    }
  }

  hideWRWhenNotParticipating() {
    if (!WrNonParticipatingBrandConfig.isCurrentBrandParticipatingToWR()) {
      $('.navbar__wyndham-rewards-container').remove();
      if ($('.language-selector').length) {
        $('.navbar__main-links').addClass('wr-non-participation')
          .removeClass('col-md-15')
          .addClass('col-md-20');
      }
    } else if(['av','lt'].includes(window.brand_id)){
      $('.navbar__wyndham-rewards-container').remove();
      if ($('.language-selector').length) {
        $('.navbar__main-links').addClass('wr-non-participation')
          .removeClass('col-md-15')
          .addClass('col-md-20');
      }
    }
  }
}

export default new GlobalNavigation();
