import { manageFavoriteHotels} from '../base/utils.js';
import Login from './login.js';
import { bookDataBeforeSSOLogin } from '../base/session-handler.js';
import Analytics from '../base/wyn-analytics-module.js';

class RenderFavourite {
  constructor() {
    this.favouriteProperties = [];
    this.handleFavouriteClick();
    this.handleToastActions();
    (this.activePropertyId = ''), (this.activeFavouriteButton = null);
  }

  getAuthenticatedState() {
    const userDetails = JSON.parse(localStorage.getItem('OT_WHG_SESSION'));
    if (userDetails) {
      return userDetails.state;
    } else return null;

  }

  async fetchFavourite(elem, propId) {
    const isAuthenticated = this.getAuthenticatedState();
    if (isAuthenticated === 'AUTHENTICATED') {
      const result = await manageFavoriteHotels('GET', null,'FavoriteHotels');
      if (result.hasOwnProperty('FavoriteHotels') && result.FavoriteHotels.length > 0) {
        const propertyIds = result.FavoriteHotels.map(hotel => hotel.PropertyId);
        this.favouriteProperties = [...this.favouriteProperties, ...propertyIds];
      }
    }
    this.getFavouriteMarkup(elem,propId);
  }

  getFavouriteMarkup(elem, propId) {
    const isFav = this.favouriteProperties.find((id) => id === propId);
    const favouriteContainer = document.createElement('div');
    favouriteContainer.classList.add('render-favourite');
    favouriteContainer.id = propId;
    const favouriteElement = document.createElement('div');
    favouriteElement.classList.add('render-favourite-heart-icon');
    const heartIcon = document.createElement('button');
    heartIcon.id = 'favourite-button';
    if (isFav) {
      heartIcon.classList.add('fav-icon-filled');
    } else {
      heartIcon.classList.add('fav-icon-outline');
    }
    favouriteElement.appendChild(heartIcon);
    favouriteContainer.appendChild(favouriteElement);
    if(elem != null) {
      elem.appendChild(favouriteContainer);
    } else {
      return favouriteContainer;
    }
  }

  getFavouriteToastMarkup(action) {
    const toastContainer = document.createElement('div');
    toastContainer.classList.add('favourite-toast');
    const toastElement = document.createElement('div');
    toastElement.classList.add('toast', 'fade-in');

    const toastContentMsg = document.createElement('div');
    toastContentMsg.classList.add('toast-message');
    const toastControls = document.createElement('span');
    toastControls.classList.add('toast-controls');
    const toastControlTitle = document.createElement('a');
    toastControlTitle.classList.add('headline-i', 'toast-undo');

    const closeButton = document.createElement('button');
    closeButton.setAttribute('type', 'button');
    closeButton.classList.add('toast-close');
    const closeIcon = document.createElement('span');
    closeIcon.classList.add('toast-close__button');
    closeButton.appendChild(closeIcon);

    toastControls.appendChild(toastControlTitle);
    toastControls.appendChild(closeButton);
    toastElement.appendChild(toastContentMsg);
    toastElement.appendChild(toastControls);
    toastContainer.appendChild(toastElement);

    if (action === 'add') {
      toastContentMsg.textContent = 'Added to Favorites';
      toastControlTitle.textContent = 'VIEW FAVORITES';

      const currentUrl = window.location.href;
      const firstSlashIndex = currentUrl.indexOf('/', currentUrl.indexOf('//') + 2);
      const baseUrl = currentUrl.substring(0, firstSlashIndex);
      const path = 'wyndham-rewards/my-favorite';
      const myFavUrl = `${baseUrl}/${path}`;
      toastControlTitle.href = myFavUrl;

    } else if (action === 'remove') {
      toastContentMsg.textContent = 'Removed from Favorites';
      toastControlTitle.textContent = 'UNDO';
    }
    toastContainer.appendChild(toastElement);
    return toastContainer;
  }

  displayToast(action) {
    this.activeFavouriteButton.style.pointerEvents = 'none';
    const toastMarkup = this.getFavouriteToastMarkup(action);
    $(document.body).append(toastMarkup);
    $('.favourite-toast').fadeIn();
  }

  hideToast() {
    setTimeout(() => {
      this.activeFavouriteButton.style.pointerEvents = 'auto';
      $('.favourite-toast').fadeOut();
      $('.favourite-toast').remove();
    }, 5000);
  }

  handleToastActions() {
    const self = this;
    $(document).on('click', '.toast-close', function() {
      self.activeFavouriteButton.style.pointerEvents = 'auto';
      $('.favourite-toast').fadeOut();
      $('.favourite-toast').remove();
    });
    $(document).on('click', '.toast-undo', async function() {
      const link = $('.toast-undo');
      if (link.text() === 'UNDO') {
        self.displayToast('add');
        const response = await manageFavoriteHotels('ADD', {
          PropertyId: self.activePropertyId,
        }, 'FavoriteHotels');
        if(response) {
          self.addToFavourite();
        }
      } else {
        Analytics.satelliteTracker('myFavoritesClick');
      }
    });
  }

  addToFavourite() {
    this.hideToast();
    this.activeFavouriteButton.classList.remove('fav-icon-outline');
    this.activeFavouriteButton.classList.add('fav-icon-filled');
    this.favouriteProperties.push(this.activePropertyId);
  }

  deleteFromFavourite() {
    this.hideToast();
    this.activeFavouriteButton.classList.remove('fav-icon-filled');
    this.activeFavouriteButton.classList.add('fav-icon-outline');
    this.favouriteProperties = this.favouriteProperties.filter((id) => id !== this.activePropertyId);
  }

  handleFavouriteClick() {
    const self = this;
    $(document).on('click', '#favourite-button', async function(event) {
      event.preventDefault();
      const isAuthenticated = self.getAuthenticatedState();
      self.activeFavouriteButton = this;
      const favouriteContainer = $(this).closest('.render-favourite');
      const propId = favouriteContainer.attr('id');
      self.activePropertyId = favouriteContainer.attr('id');
      window.digitalData.property.propertyInfo.favoriteSiteID = propId;
      Analytics.satelliteTracker('heartFavClick');
      if(isAuthenticated === 'AUTHENTICATED') {
        const isFav = self.favouriteProperties.find((id) => id === propId);
        if (isFav) {
          self.displayToast('remove');
          const response = await manageFavoriteHotels('DELETE', {
            PropertyId: propId,
          }, 'FavoriteHotels');
          if(response) {
            self.deleteFromFavourite();
          }
        } else {
          self.displayToast('add');
          const response = await manageFavoriteHotels('ADD', {
            PropertyId: propId,
          }, 'FavoriteHotels');
          if(response) {
            self.addToFavourite();
          }
        }
      } else {
        // redirect to login page
        bookDataBeforeSSOLogin();
        Login.redirectToOktaLogin(null);
      }
    });
  
  }
}

export default new RenderFavourite();
